@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    background: #212121;
    color: #fff;
    scroll-behavior: smooth;
}

@layer components {
    .page-section {
        @apply h-screen container mx-auto;
    }
}
